import Link from 'next/link'
import { toast } from 'react-toastify'

import { SHOW_TOAST, ToastInfos } from '../types/toast'

const MAX_TOAST = 5
const toastList = new Set()
const initialState: ToastInfos = {
  toastIds: [],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_TOAST: {
      const msg = action.payload.msg
      const type = action.payload.type
      const autoClose = Number(action.payload.autoClose) || 3000
      if (type === 'info') {
        const toastId = toast.info(msg, {
          theme: 'colored',
          onClose: function () {
            toastList.delete(toastId)
          },
          autoClose,
        })
        toastList.add(toastId)
      } else if (type === 'warning') {
        const toastId = toast.warning(msg, {
          theme: 'colored',
          onClose: function () {
            toastList.delete(toastId)
          },
        })
        toastList.add(toastId)
      } else if (type === 'error') {
        const toastId = toast.error(msg, {
          theme: 'colored',
          onClose: function () {
            toastList.delete(toastId)
          },
          autoClose,
        })
        toastList.add(toastId)
      } else if (type === 'success') {
        const toastId = toast.success(msg, {
          theme: 'colored',
          onClose: function () {
            toastList.delete(toastId)
          },
          autoClose,
        })
        toastList.add(toastId)
      } else if (type === 'confirm') {
        const CustomToastWithLink = () => (
          <div className="flex flex-row justify-between">
            <p className="toast-confirm-title mr-[10px]">{msg}</p>
            <Link prefetch={false} href="/my-followings" className="toast-confirm-view-all">
              View All
            </Link>
          </div>
        )

        const toastId = toast.success(CustomToastWithLink, {
          theme: 'colored',
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: false,
          onClose: function () {
            toastList.delete(toastId)
          },
          autoClose,
        })
        toastList.add(toastId)
      } else if (type === 'non_r_last_free_search') {
        const CustomToastWithLink = () => (
          <div className="flex flex-row justify-between">
            <p className="toast-confirm-title mr-[10px]">
              {msg}{' '}
              <Link prefetch={false} href="/signup/" className="toast-confirm-view-all">
                Register a free account
              </Link>{' '}
              on LTH.
            </p>
          </div>
        )

        const toastId = toast.success(CustomToastWithLink, {
          theme: 'colored',
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          autoClose: 8000,
          onClose: function () {
            toastList.delete(toastId)
          },
          //autoClose,
        })
        toastList.add(toastId)
      } else if (type === 'r_last_free_search') {
        const CustomToastWithLink = () => (
          <div className="flex flex-row">
            <p className="toast-confirm-title">
              {msg}
              <Link
                href="https://hub.legaltechnologyhub.com/more-info"
                target="_blank"
                className="toast-confirm-view-all"
                onClick={() => {
                  toastList.delete(toastId)
                }}
              >
                Sign up to LTH Premium
              </Link>{' '}
              for unlimited searches.
            </p>
          </div>
        )

        const toastId = toast.success(CustomToastWithLink, {
          theme: 'colored',
          hideProgressBar: true,
          closeButton: false,
          closeOnClick: true,
          autoClose: 8000,
          onClose: function () {
            toastList.delete(toastId)
          },
          //autoClose,
        })
        toastList.add(toastId)
      }

      if (toastList.size > MAX_TOAST) {
        const first = toastList.values().next().value
        toast.dismiss(first)
        toastList.delete(first)
      }

      return {
        ...state,
      }
    }
    default:
      return state
  }
}

export default reducer
